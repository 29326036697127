<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{'open' : navServices.collapseSidebar}" id="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left d-lg-none">
      <div class="logo-wrapper">
        <a routerLink="/user/home" href="javascript:void(0)"><img src="assets/images/logo/logo-vusa.png" alt=""></a>
      </div>
      <div class="mobile-sidebar d-none d-lg-block">
        <div class="media-body text-right switch-sm">
          <label class="switch">
            <a href="javascript:void(0)" (click)="collapseSidebar()">
              <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
            </a>
          </label>
        </div>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{'open' : openNav}">

        <li>
          <form class="form-inline search-form">
            <div class="form-group">
              <input class="form-control-plaintext" [class.open]="isOpenMobile" [(ngModel)]="queryString"
                [ngModelOptions]="{standalone: true}" (keyup)="search($event)" type="text" autocomplete="off"
                placeholder="Recherche...">
              <span class="d-sm-none mobile-search">
                <app-feather-icons [icon]="'search'" (click)="isOpenMobile=!isOpenMobile"></app-feather-icons>
              </span>
              <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
                <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice:0:8">
                  <div class="ProfileCard-avatar">
                    <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
                  </div>
                  <div class="ProfileCard-details">
                    <div class="ProfileCard-realName">
                      <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{menuItem?.title}}</a>
                    </div>
                  </div>
                </div>

              </div>
              <!-- <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
                  <div class="tt-dataset tt-dataset-0">
                    <div class="EmptyMessage">
                      Opps!! There are no result found.
                    </div>
                  </div>
                </div> -->
            </div>
          </form>
        </li>
        <li>
          <ul class="nav-menus-icons">
            <li class="onhover-dropdown">
              <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot">{{length()}}</span>
              <ul class="notification-dropdown onhover-show-div">
                <li>Notification <span class="badge badge-pill badge-primary pull-right">3</span></li>
                <li *ngFor="let notif of notifications">
                  <div class="media">
                    <div class="media-body">
                      <h6 class="mt-0"><span>
                          <app-feather-icons class="shopping-color" [icon]="'shopping-bag'"></app-feather-icons>
                        </span>{{notif.texte}}<small class="pull-right">9:00 AM</small></h6>
                      <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li style="border-left: none !important;" *ngIf="boShowSetting" placement="bottom" title="Paramètre">
              <a
                [routerLink]="['admin']">
                <app-feather-icons [icon]="'settings'"></app-feather-icons>
              </a></li>
            <li placement="bottom" ngbTooltip="Se déconnecter"
              style="border-left: 1px solid #eff0f1 !important;padding-right: 0;" (click)="logout()"><a>
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>
              </a>
            </li>
          </ul>
        </li>
        
       
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->