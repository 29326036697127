<div class="contenu">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12" *ngIf="resultat?.length > 0; else emptyBlock">
        <div *ngIf="searchInProgress==false">
          <span>{{resultat.length}} dossier(s) trouvé(s) contenant le terme
            <strong>{{query}}</strong>
          </span>
        </div>
        <div *ngIf="searchInProgress==true">
          <h4>Recherche en cours...</h4>
        </div>

        <!-- <mat-card *ngFor="let element of resultat" class="mt-2">
          <mat-card-header>
            <mat-card-title> <a [routerLink]="'/user/search/'+element.data[0].encoded+'/'+query">
                {{element.title}}</a></mat-card-title>
            <mat-card-subtitle> {{element.description}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
          </mat-card-content>
        </mat-card>-->


        <mat-accordion>
          <mat-expansion-panel *ngFor="let element of resultat" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title> {{element.title}} ({{element?.data?.length}})</mat-panel-title>
              <mat-panel-description> {{element.description}} </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let data of element.data" class="itemData">
              <mat-list *ngFor="let head of element.header" role="list">
                <mat-list-item role="listitem"><span class="title">{{head.name}} : </span>
                  <span class="value"
                    *ngIf="(!edit || head.readonly ) && head.type!='date' && head.type!='number' && head.type!='hypertext'">{{data[head.prop][0]["value"]}}</span>

                  <span class="value" *ngIf="(!edit|| head.readonly)  && head.type=='hypertext'"><a
                      href="{{data[head.prop][0]['value']}}" target="_blank">cliquer ici</a></span>

                  <span class="value"
                    *ngIf="(!edit|| head.readonly)  && head.type=='date'">{{(data[head.prop][0]["value"])?(data[head.prop][0]["value"]
                    | date:"dd/MM/yyyy"):''}} </span>

                  <span class="value" *ngIf="(!edit|| head.readonly)  && head.type=='number'">
                    {{(data[head.prop][0].value| number:'1.2-2':'fr')}}</span>




                </mat-list-item>
              </mat-list>

            </div>
          </mat-expansion-panel>
        </mat-accordion>


      </div>
      <ng-template #emptyBlock>
        <h4>Aucun document trouvé pour le terme <strong>{{query}}</strong></h4>
      </ng-template>




    </div>
  </div>