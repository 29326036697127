import { Component, Input, OnInit } from '@angular/core';
import { PropertyService } from '../../shared/services/property.service';
import { Property } from '../../shared/model/property';
import { AddPropertyComponent } from '../add-property/add-property.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators'
import { iif, of } from 'rxjs';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit {
  @Input() dossier;
  listProperty: Property[];
  constructor(private propertyService: PropertyService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.listProperty = this.propertyService.listProperties;
  }

  add() {

  }
  close() {

  }
  /**
   * Modifier les propriétés
   * @param prop 
   */
  modifPropriete(prop: Property) {
    console.log("Property : ", prop);
    let dialogRef = this.dialog.open(AddPropertyComponent, {
      width: '500px',
      data: {
        prop: prop,
        mode: 'update'
      }
    });
    dialogRef.afterClosed().pipe(
      switchMap((res) => this.resolveProperties(res)
      )
    ).subscribe((result: any) => {
      console.log('The dialog was closed');
      console.log(result);
      if (result) {
        this.listProperty = result;
        //  this.propertyService.saveProperty(prop).subscribe(res=>{});
        // this.findPropertiesNotInDossier(this.dossier);
      }
    });
  }
  resolveProperties(res): any {
    return iif(() => this.checkClose(res),
      this.propertyService.getListeProperty(this.dossier),
      of(null));
  }
  checkClose(res: any): boolean {
    if (res) return true;
    return false;
  }
}
