
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../model/notification';
var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];
  public notifications: Notification[];
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string
  public queryString: string;
  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  isopen: boolean = false;
  boShowSetting: boolean = false;
  boProfile: boolean;
  public isOpenMobile: boolean = true
  public elem;
  document: any;
  constructor(public navServices: NavService,
    public authService: AuthService,
    private translate: TranslateService,
    private notifService: NotificationService,
    private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });
    this.boProfile = false;
    this.boShowSetting = this.authService.user.roles.indexOf('admin', 0) != -1;
    this.notifications.push({
      'texte': 'Baux triennaux',
      'email': 'e@gmail.com',
      'sender': 'emile',
      'notifId': 1
    });
    this.notifications.push({
      'texte': 'Baux traités',
      'email': 'e@gmail.com',
      'sender': 'emile',
      'notifId': 2
    });


    /* this.notifService.getListNotification(this.authService.user.email).subscribe(notifications => {
       this.notifications = notifications
     });*/


  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  openMobileNav() {
    this.openNav = !this.openNav;
  }
  public changeLanguage(lang) {
    this.translate.use(lang)
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  search(event: KeyboardEvent) {
    // alert(this.queryString);
    console.log("event === ", event);
    if (event.code !==  "Enter" ) {
      return;
    }
    this.router.navigate(['user/search/all/', this.queryString], { relativeTo: this.activatedRoute });
  }
  advSearch() {
    document.getElementById("mySidepanel").style.width = (this.isopen) ? "0%" : "100%";
    this.isopen = !this.isopen;
  }

  logout() {
    this.authService.logoff();
  }
  length() {
    this.notifications ? this.notifications.length : 0
  }
  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
