<mat-tab-group class="groupe search-tab" animationDuration="1000ms" (selectedTabChange)="onLinkClick($event)"
  [selectedIndex]="selected" style="padding:0px;">
  <mat-tab label="Resultat ({{datas.length}})" *ngIf="columns.length > 0" style="padding:0px;">
    <app-tab-result [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [title]="title" [dossier]="dossier"
      [subtitle]="subtitle" [columnTotal]="columnTotal" (docUploaded)="refreshTable()" (selectedRow)="open($event)">
    </app-tab-result>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p style="color: white">Veuillez patienter... </p>
    </ngx-spinner>

  </mat-tab>

  <mat-tab *ngFor="let item of listCurrentMeta;let i=index;let der=last" id="index{{i}}"
    [label]="(item[columns[0].prop][0]['value'])?item[columns[0].prop][0]['value']:'*nouveau'" style="padding:0px;">

    <ng-template mat-tab-label>

      <div class="col d-flex justify-content-start" style="margin-left: 10px;">
        {{(item[columns[0].prop][0]['value'])?item[columns[0].prop][0]['value']:'*nouveau'}}</div>
      <div class="col d-flex justify-content-end">
        <button style="color:black" mat-icon-button (click)="remove(item)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </ng-template>
    <div class="contenu">
      <app-object-detail [meta]="item" [cols]="columns" [dossier]="dossier" (refreshList)="launch(dossierUUID)"
        *ngIf="!currrentDossier?.dataOnly">
      </app-object-detail>
      <app-object-data-detail [meta]="item" [cols]="columns" [dossier]="dossier" (refreshList)="launch(dossierUUID)"
        *ngIf="currrentDossier?.dataOnly">
      </app-object-data-detail>
    </div>
  </mat-tab>
  <mat-tab label="Rapports" *ngIf="existSommable && (columns.length>0)" style="padding:0px;">
    <app-tab-rapport [columnList]="columnList" [columnTotal]="columnTotal" [dataSource]="dataSource"
      [filteredDataSource]="filteredDataSource">
    </app-tab-rapport>
  </mat-tab>
</mat-tab-group>