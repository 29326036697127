import { Component, OnInit, Input, Inject } from '@angular/core';
import { Dossier } from '../../shared/model/dossier';
import { DossierService } from '../../shared/services/dossier.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-admin-modif-doss',
  templateUrl: './admin-modif-doss.component.html',
  styleUrls: ['./admin-modif-doss.component.scss']
})
export class AdminModifDossComponent implements OnInit {
  /** TDOD: passer le dossier depuis le composent appelant le dialog via l'objet data de MatDialog */
  dossier: Dossier;
  listDossiers: Dossier[];
  //dossierForm: FormGroup;
  parent;
  icons: string[];
  visible: boolean = false;
  constructor(private dossierService: DossierService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<AdminModifDossComponent>,
    //public activeModal: NgbActiveModal

    @Inject(MAT_DIALOG_DATA) public data: Dossier
  ) {
    this.dossier = data;
    console.log(this.dossier);
  }

  ngOnInit(): void {
    //this.initForm();
    this.listDossiers = this.dossierService.dossiers;
    this.icons = this.dossierService.icons;
  }
  selectIcon(id) {
    this.dossier.icon = id
  }
  initForm() {
    if (this.dossier == undefined) {
      this.dossier = new Dossier(0, '*nouveau', '', '', 0, []);
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }
  save(): void {

    if (this.dossier.dossierId == 0 || this.dossier.dossierId == null) {
      this.dossierService.save(this.dossier).subscribe(dos => {
        this.dossier = dos;
        this.toastrService.success("Dossier sauvegardé avec succès.")
        this.dialogRef.close(true);
      },
        error => {
          this.toastrService.error("Une erreur s'est produite lors de l'enregistrement du dossier")
          this.dialogRef.close(false);
        });
    } else {
      this.dossierService.updateDossier(this.dossier).subscribe(dos => {
        this.dossier = dos;
        this.toastrService.success("Dossier sauvegardé avec succès.")
        this.dialogRef.close(true);
      },
        error => {
          this.toastrService.error("Une erreur s'est produite lors de l'enregistrement du dossier")
          this.dialogRef.close(false);
        });
    }

  }
}
